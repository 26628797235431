<template>
    <ListingComponent :info="info" />
</template>
<script>
import ListingComponent from './ListingPage/index.vue';

export default {
    name: "FeelingCustom",
    components: {
        ListingComponent
    },
    data: function () {
        return {
            info: {
                pageName: "Feeling",
                pageUrl: "/custom",
                endpoint: '/admin/all-feelings',
                endpointSearch: 'admin/livesearch-feelings',
                keysToDisplay: ['id', 'name', 'status'],
                endpointStatus: '/admin/feeling-status',
                addPageUrl: '/custom/add',
                addPageEndpoint: '/admin/create-feeling',
                formDataInsert: [
                    { 
                        objKey : 'name',
                        label: 'Feelings', 
                        name: 'feelings', 
                        value: '' , 
                        placeholder : 'Enter comma seperated feelings here',
                        splitColumnData: true,
                        type : 'input'
                    },
                ],
            }
        };
    },

    mounted() {
        this.$store.commit('setInfo',this.info);
    }
}
</script>
